.container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;

  flex-direction: column;
  align-items: center;
  position: relative;
}

.nav {
  width: 79%;
  height: 50px;
  background: #000;
  padding-right: 20px;
  color: aliceblue;
  direction: rtl;
}

.main {
  display: flex;
  justify-content: center;
  align-items: start;
  width: 80%;
  margin: auto;
}
.row {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.card {
  min-width: 350px;
  height: 350px;
  margin: 3px;
  display: flex;
  flex-direction: column;

  justify-content: start;
  width: 32.5%;
  align-items: center;
}

img {
  width: 80%;
  height: 250px;
}

ul {
  list-style: none;
  display: flex;
}
@media only screen and (max-width: 700px) {
  .card {
    width: 100%;
    height: 350px;
  }
  .row {
    width: 99%;
  }
  img {
    width: 50%;
    height: 250px;
  }
  .nav {
    width: 100%;
  }
  .main {
    width: 100%;
  }
  .row {
    width: 100%;
  }
}
@media only screen and (max-width: 1000px) and (min-width: 700px) {
  .card {
    width: 49%;
  }
  .nav {
    width: 100%;
  }
  .main {
    width: 100%;
  }
}
.sum {
  width: 100%;
  display: flex;
  justify-content: center;
}
.form {
  display: flex;
  flex-direction: column;
  width: 20%;
}
